import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-tallahassee-florida.png'
import image0 from "../../images/cities/scale-model-of-goodwood-museum-&-gardens-in-tallahassee-florida.png"
import image1 from "../../images/cities/scale-model-of-mission-san-luis-in-tallahassee-florida.png"
import image2 from "../../images/cities/scale-model-of-knott-house-museum-in-tallahassee-florida.png"
import image3 from "../../images/cities/scale-model-of-florida-historic-capitol-museum-in-tallahassee-florida.png"
import image4 from "../../images/cities/scale-model-of-tallahassee-museum-in-tallahassee-florida.png"
import image5 from "../../images/cities/scale-model-of-usda-forest-service-in-tallahassee-florida.png"
import image6 from "../../images/cities/scale-model-of-florida-state-capitol-in-tallahassee-florida.png"
import image7 from "../../images/cities/scale-model-of-lichgate-on-high-road-in-tallahassee-florida.png"
import image8 from "../../images/cities/scale-model-of-riley-house-museum-in-tallahassee-florida.png"
import image9 from "../../images/cities/scale-model-of-visit-tallahassee-in-tallahassee-florida.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Tallahassee'
            state='Florida'
            image={image}
            lat='30.4551'
            lon='-84.2534'
            attractions={ [{"name": "Goodwood Museum & Gardens", "vicinity": "1600 Miccosukee Rd, Tallahassee", "types": ["museum", "point_of_interest", "establishment"], "lat": 30.45656, "lng": -84.25681759999998}, {"name": "Mission San Luis", "vicinity": "2100 W Tennessee St, Tallahassee", "types": ["museum", "point_of_interest", "establishment"], "lat": 30.4486503, "lng": -84.3200536}, {"name": "Knott House Museum", "vicinity": "301 E Park Ave, Tallahassee", "types": ["museum", "point_of_interest", "establishment"], "lat": 30.4416096, "lng": -84.2793792}, {"name": "Florida Historic Capitol Museum", "vicinity": "400 S Monroe St, Tallahassee", "types": ["museum", "point_of_interest", "establishment"], "lat": 30.4381315, "lng": -84.28128770000001}, {"name": "Tallahassee Museum", "vicinity": "3945 Museum Rd, Tallahassee", "types": ["museum", "restaurant", "food", "point_of_interest", "establishment"], "lat": 30.4104867, "lng": -84.34403729999997}, {"name": "USDA Forest Service", "vicinity": "325 John Knox Rd, Tallahassee", "types": ["park", "point_of_interest", "establishment"], "lat": 30.4739097, "lng": -84.28601700000002}, {"name": "Florida State Capitol", "vicinity": "400 S Monroe St, Tallahassee", "types": ["point_of_interest", "local_government_office", "establishment"], "lat": 30.4380598, "lng": -84.28207090000001}, {"name": "Lichgate on High Road", "vicinity": "1401 High Rd, Tallahassee", "types": ["point_of_interest", "establishment"], "lat": 30.4605246, "lng": -84.3131788}, {"name": "Riley House Museum", "vicinity": "419 E Jefferson St, Tallahassee", "types": ["museum", "point_of_interest", "establishment"], "lat": 30.4394771, "lng": -84.2773707}, {"name": "Visit Tallahassee", "vicinity": "106 E Jefferson St, Tallahassee", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 30.439841, "lng": -84.28155099999998}] }
            attractionImages={ {"Goodwood Museum & Gardens":image0,"Mission San Luis":image1,"Knott House Museum":image2,"Florida Historic Capitol Museum":image3,"Tallahassee Museum":image4,"USDA Forest Service":image5,"Florida State Capitol":image6,"Lichgate on High Road":image7,"Riley House Museum":image8,"Visit Tallahassee":image9,} }
       />);
  }
}